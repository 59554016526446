import React from 'react'
import { Map, MapOptions } from './Map'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone } from '@fortawesome/pro-solid-svg-icons'
import { faSkype } from '@fortawesome/fontawesome-free-brands'
import ContactForm from './ContactForm';
import Divider from '../common/Divider'

class ContactBottom extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isFormOpen: true,
      mapZoom: MapOptions.zaven.local.zoom,
      mapLocation: MapOptions.zaven.local.location,
      marker: null,
    }
  }

  onCloseFormClick = e => {
    e.preventDefault()
    this.setState({
      isFormOpen: false,
      mapZoom: MapOptions.zaven.global.zoom,
      mapLocation: MapOptions.zaven.global.location,
      marker: MapOptions.zaven.marker,
    })
  }

  onOpenFormClick = e => {
    e.preventDefault()
    this.setState({
      isFormOpen: true,
      mapZoom: MapOptions.zaven.local.zoom,
      mapLocation: MapOptions.zaven.local.location,
      marker: null,
    })
  }

  render() {
    const {
      city,
      skype,
      street,
      country,
      legalName,
      phoneClean,
      workTimeUTC,
      skypeName,
      addressUrl,
      phone,
      email,
      home
    } = this.props

    const currentTimeUTC = new Date().getUTCHours()

    const isWithinWorkingHours =
      currentTimeUTC >= workTimeUTC.from && currentTimeUTC < workTimeUTC.to

    return (
      <section id="contact-partial" className="contact">
        <div className="contact-layer-text">
          <div className="container">
            <div className="row">
              <div className="col">
                <div
                  className="seo-h1 text-center text-lowercase mt-0 mb-5"
                  id="contact-partial-header"
                >
                  <h3 className="m-0 seo-h1">
                  Sus ideas nos parecen fantásticas! 
                  <br></br>
                  Pongámoslas en práctica
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <ContactForm
              isOpen={this.state.isFormOpen}
              onCloseFormClick={this.onCloseFormClick}
              reCaptchaSiteKey={process.env.GATSBY_RECAPTCHA_SITE_KEY_V3}
              mailingUrl={`${process.env.GATSBY_MAILING_URL}/api/contact`}
              home
            />
            <div className="row">
              <div className="col-lg-3 col-md-2 col-sm-12" />
              <div className="col-lg-3 push-lg-3 col-md-4 push-md-4 col-sm-6 push-sm-6">
                <ul className="fa-ul contact-options d-none d-md-block">
                  <li>
                    <FontAwesomeIcon icon={faEnvelope} className="fa-li" />
                    <a href={`mailto:${email}`} className="link-unstyled">
                      {email}
                    </a>
                  </li>
                  <li>
                    <FontAwesomeIcon
                      icon={faPhone}
                      className="fa-li"
                      style={
                        isWithinWorkingHours
                          ? { color: '#27ae60' }
                          : { color: '#c0392b', transform: 'rotate(-135deg)' }
                      }
                    />
                    <a href={`tel:${phoneClean}`} className="link-unstyled">
                      {phone}
                    </a>
                    <p
                      className="text-sm"
                      style={{ lineHeight: 1, marginTop: '-3px' }}
                    >
                      <small>GMT+1 (Lun-Vie 9am-5pm)</small>
                    </p>
                  </li>
                  <li>
                    <FontAwesomeIcon
                      icon={faSkype}
                      className="fa-li"
                      // style={isWithinWorkingHours ? {color: '#3baaf3'} : {color: '#939393'}}
                    />
                    <a href={`skype:${skype}`} className="link-unstyled">
                      {skypeName}
                    </a>
                  </li>
                </ul>
                <div
                  className="contact-options-mobile d-md-none d-block"
                  style={{ marginBottom: '30px' }}
                >
                  <a
                    href={`mailto:${email}`}
                    className="btn btn-secondary btn-block"
                  >
                    <FontAwesomeIcon icon={faEnvelope} />
                    &nbsp;
                    {email}
                    &nbsp;
                  </a>
                  <a
                    href={`tel:${phoneClean}`}
                    className="btn btn-secondary btn-block"
                  >
                    <FontAwesomeIcon icon={faPhone} />
                    &nbsp;
                    {phone}
                    &nbsp;
                    <span className="text-xs" style={{ opacity: 0.7 }}>
                      GMT+1
                    </span>
                  </a>
                </div>
              </div>
              <div className="col-lg-3 pull-lg-3 col-md-4 pull-md-4 col-sm-6 pull-sm-6">
                <div className="text-center text-sm-left">
                  <a
                    className="link-unstyled"
                    target="_blank"
                    href={addressUrl}
                    rel="noopener noreferrer"
                  >
                    <address>
                      {legalName}
                      <br />
                      {street}
                      <br />
                      {city} ({country})<br />
                    </address>
                  </a>
                </div>
              </div>
            </div>
            <p
              className="text-center d-none d-md-block"
              style={{ margin: '20px' }}
            >
              <small>
                {' '}
                <button
                  className="button-like-link"
                  onClick={this.onOpenFormClick}
                >
                  
                </button>
                .
              </small>
            </p>
          </div>
          <Divider rainbow fullWidth />
          <Map
            location={this.state.mapLocation}
            zoom={this.state.mapZoom}
            marker={this.state.marker}
            width="100%"
            height="450px"
            hideOnMobile
          />
        </div>
      </section>
    )
  }
}

export default ContactBottom
